import {MdOutlineMenu} from 'react-icons/md';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';

import DesktopHeader from '../components/DesktopHeader';
import {updateShowDrawerMenu} from '../redux/slices/generalSlice';
import Loading from '../components/Loading';
import Drawer from '../components/Drawer';
import axios from '../axios';
import {get} from '../helpers';

export default function Settings() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [notificationSettings, setNotificationSettings] = useState({});

  const ignitionOnNotification = get(notificationSettings, 'ignition_on', true);
  const ignitionOffNotification = get(notificationSettings, 'ignition_off',
      true);
  const offlineNotification = get(notificationSettings, 'offline', true);
  const overSpeedNotification = get(notificationSettings, 'over_speed', true);

  useEffect(() => {
    setLoading(true);
    axios
        .get('/user/info')
        .then((response) => {
          if (response?.success) {
            setNotificationSettings(
                get(response, 'user.notification_settings', {}));
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
  }, []);

  const saveUserSettings = (key, value) => {
    setLoading(true);
    let newSettings = {...notificationSettings};
    newSettings[key] = value;
    axios.post('/user/settings/update', newSettings)
        .then((response) => {
          if (response?.success) {
            setNotificationSettings(
                get(response, 'notification_settings', {}));
          }
          toast.success('Settings updated successfully!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          toast.error('Unable to update the settings!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        });
  };

  return (
      <div className="flex md:flex-row flex-col h-full relative">
        <div className="bg-blue-950 w-16 md:block hidden">
          <DesktopHeader/>
        </div>
        <div>
          <div
              className="w-full bg-white shadow md:hidden block px-2 py-4 flex justify-between items-center">
            <button
                className="cursor-pointer"
                onClick={() => {
                  dispatch(updateShowDrawerMenu(true));
                }}
            >
              <MdOutlineMenu size={24} color="#001C6F"/>
            </button>
            <div className="flex-1">
              <div className="text-blue-950 text-base font-bold text-center">
                Settings
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 relative p-6">
          <div className="text-blue-950 text-base font-bold">
            Notification setting
          </div>
          <div className="md:max-w-md w-full">
            <div className="w-full">
              <div className="flex items-center justify-between mt-5">
                <div className="text-neutral-800 font-normal">
                  Ignition ON notification
                </div>
                <div>
                  <label className="toggle">
                    <input
                        type="checkbox"
                        checked={ignitionOnNotification}
                        onChange={() => {
                          saveUserSettings('ignition_on', !ignitionOnNotification,);
                        }}
                    />
                    <span className="slider"/>
                    <span className="labels" data-on="Yes" data-off="No"></span>
                  </label>
                </div>
              </div>
              <div className="flex items-center justify-between mt-5">
                <div className="text-neutral-800 font-normal">
                  Ignition OFF notification
                </div>
                <div>
                  <label className="toggle">
                    <input
                        type="checkbox"
                        checked={ignitionOffNotification}
                        onChange={() => {
                          saveUserSettings('ignition_off', !ignitionOffNotification,);
                        }}
                    />
                    <span className="slider"/>
                    <span className="labels" data-on="Yes" data-off="No"></span>
                  </label>
                </div>
              </div>
              <div className="flex items-center justify-between mt-5">
                <div className="text-neutral-800 font-normal">
                  Over speed notification
                </div>
                <div>
                  <label className="toggle">
                    <input
                        type="checkbox"
                        checked={overSpeedNotification}
                        onChange={() => {
                          saveUserSettings('over_speed', !overSpeedNotification,);
                        }}
                    />
                    <span className="slider"/>
                    <span className="labels" data-on="Yes" data-off="No"></span>
                  </label>
                </div>
              </div>
              <div className="flex items-center justify-between mt-5">
                <div className="text-neutral-800 font-normal">
                  Offline notification
                </div>
                <div>
                  <label className="toggle">
                    <input
                        type="checkbox"
                        checked={offlineNotification}
                        onChange={() => {
                          saveUserSettings('offline', !offlineNotification,);
                        }}
                    />
                    <span className="slider"/>
                    <span className="labels" data-on="Yes" data-off="No"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading && <Loading/>}
        <Drawer/>
      </div>
  );
}
