import React from 'react';
import {confirmAlert} from 'react-confirm-alert';
import {useDispatch, useSelector} from 'react-redux';
import {AiOutlineClose, AiFillUnlock, AiFillLock} from 'react-icons/ai';
import {MdReplay} from 'react-icons/md';
import {BsFillPencilFill, BsShareFill} from 'react-icons/bs';
import {useNavigate} from 'react-router-dom';

import {
  setMapBounds,
  unselectSingleDevice,
} from '../redux/slices/deviceSlice';
import DeviceCard from './DeviceCard';
import {
  updatePlaybackPopup,
  updateVehicleInfoPopup,
} from '../redux/slices/generalSlice';
import {enableOrDisableSafeParking} from '../Actions';
import {get} from '../helpers';

export default function DevicesDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedDevice = useSelector((state) => state.device.selectedDevice);
  const allDevices = useSelector((state) => state.device.list);

  const device = allDevices.find((obj) => obj.imei === selectedDevice);
  if (!device) {
    return null;
  }

  const safeParking = get(device, 'safe_parking_location', null) !== null;

  return (
      <div
          className="absolute z-[100] md:top-0 right-0 bottom-0 md:w-20 w-full md:py-6 md:pr-6 md:items-center md:flex">
        <div className="md:hidden block p-2">
          <DeviceCard
              device={device}
              key={`device-select-${device.imei}`}
          />
        </div>
        <div
            className="bg-white md:rounded-lg shadow md:block hidden flex flex-col w-full items-center justify-center">
          <button
              className="w-full flex flex-col items-center justify-center cursor-pointer my-2"
              onClick={() => {
                dispatch(unselectSingleDevice());
                dispatch(setMapBounds([]));
              }}
          >
            <span>
              <AiOutlineClose color="#7F7F7F" size={24}/>
            </span>
            <span className="text-zinc-500 text-xs font-normal text-center">
              Close
            </span>
          </button>
          <button
              className="w-full flex flex-col items-center justify-center cursor-pointer my-4"
              onClick={() => {
                dispatch(updatePlaybackPopup(true));
              }}
          >
            <span>
              <MdReplay color="#7F7F7F" size={24}/>
            </span>
            <span className="text-zinc-500 text-xs font-normal text-center">
              Playback
            </span>
          </button>
          <button
              className="w-full flex flex-col items-center justify-center cursor-pointer my-4"
              onClick={() => {
                if (safeParking) {
                  enableOrDisableSafeParking(selectedDevice, dispatch)
                      .then((response) => {
                      });
                } else {
                  confirmAlert({
                    title: 'Confirm to enable safe parking',
                    message: 'Are you sure to do enable safe parking?',
                    buttons: [
                      {
                        label: 'Yes',
                        onClick: () => {
                          enableOrDisableSafeParking(selectedDevice, dispatch)
                              .then((response) => {
                              });
                        },
                      },
                      {
                        label: 'No',
                        onClick: () => {
                        },
                      },
                    ],
                  });
                }
              }}
          >
            <span>
              {safeParking ? (
                  <AiFillLock color="#0EB600" size={24}/>
              ):(
                  <AiFillUnlock color="#7F7F7F" size={24}/>
              )}
            </span>
            <span className={`${safeParking ?
                'text-green-600 text-bold':
                'text-zinc-500'} text-xs font-normal text-center`}>
              Safe Park
            </span>
          </button>
          <button
              className="w-full flex flex-col items-center justify-center cursor-pointer my-4"
              onClick={() => {
                dispatch(updateVehicleInfoPopup(true));
              }}
          >
            <span>
              <BsFillPencilFill color="#7F7F7F" size={24}/>
            </span>
            <span className="text-zinc-500 text-xs font-normal text-center">
              Edit
            </span>
          </button>
          <button
              className="w-full flex flex-col items-center justify-center cursor-pointer my-4"
              onClick={() => {
                navigate(`/share/vehicle/${selectedDevice}`);
              }}
          >
            <span>
              <BsShareFill color="#7F7F7F" size={24}/>
            </span>
            <span className="text-zinc-500 text-xs font-normal text-center">
              Share
            </span>
          </button>
        </div>
      </div>
  );
}
