import React, {useEffect, useState} from 'react';
import io from 'socket.io-client';

import {get, getImageUrl} from '../helpers';
import Loading from '../components/Loading';
import PublicGoogleMaps from '../components/PublicGoogleMaps';
import {useParams} from 'react-router-dom';
import DeviceCard from '../components/DeviceCard';

export default function PublicHome() {
  const [locations, setLocations] = useState([]);
  const [deviceInfo, setDeviceInfo] = useState({});
  const [loading, setLoading] = useState(true);

  const {shareToken} = useParams();

  useEffect(() => {
    const socket = io(process.env.REACT_APP_WS_URL, {
      withCredentials: false,
      extraHeaders: {
        share_token: shareToken,
      },
    });

    socket.on('user_devices', (device) => {
      setDeviceInfo(device);
      setLocations((allLocations) => {
        let previousLocations = [...allLocations];
        previousLocations.push({
          'lat': get(device, 'terminal.last_location.latitude', 0),
          'lng': get(device, 'terminal.last_location.longitude', 0),
          'bearing': get(device, 'terminal.last_location.course', 0),
          'speed': get(device, 'terminal.last_location.speed', 0),
        });
        return previousLocations;
      });

      setTimeout(() => {
        const imageUrl = getImageUrl(
            device.type,
            device.terminal.status,
            device.imei,
        );
        const marker = document.querySelector(`[src="${imageUrl}"]`);
        console.log(marker);
        if (marker) {
          let rotation = device.terminal.last_location.course;
          marker.style.transform = `rotate(${rotation - 90}deg)`;
        }
        setLoading(false);
      }, 2000);
    });

    socket.on('device_location_changed', (response) => {
      setDeviceInfo((previousDeviceInfo) => {
        previousDeviceInfo['terminal'] = response.newStatus;

        return previousDeviceInfo;
      });
      setLocations((allLocations) => {
        let previousLocations = [...allLocations];
        previousLocations.push({
          'lat': get(response, 'location.latitude', 0),
          'lng': get(response, 'location.longitude', 0),
          'bearing': get(response, 'location.course', 0),
          'speed': get(response, 'location.speed', 0),
        });
        return previousLocations;
      });
    });

    return () => {
      if (socket) {
        socket.close();
      }
    };

  }, [shareToken]);

  if (loading) {
    return <Loading/>;
  }

  return (
      <div className="flex md:flex-row flex-col h-full relative">
        <div className="flex-1 relative">
          {locations.length > 0 && (
              <div className="h-full map__container">
                <PublicGoogleMaps locations={locations} device={deviceInfo}/>
              </div>
          )}
          <div className="top-0 left-0 absolute md:w-96 w-full p-2">
            <DeviceCard
                device={deviceInfo}
                selected={false}
                minimal={true}
                onSelect={() => {
                }}
            />
            {deviceInfo.driver.phone && <div className="mt-4">
              <div className="mt-2 flex">
                <a
                    href={`tel:${deviceInfo.driver.phone}`}
                    className="px-2 py-3 bg-blue-950 text-white text-bold rounded w-full text-center"
                >
                  Call Driver
                </a>
              </div>
            </div>}
          </div>
        </div>
      </div>
  );
}
