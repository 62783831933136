import {useCallback, useEffect} from 'react';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {ProtectedRoute} from './ProtectedRoute';
import Login from '../pages/Login';
import Home from '../pages/Home';
import Settings from '../pages/Settings';
import Vehicles from '../pages/Vehicles';
import VehicleSharing from '../pages/VehicleSharing';
import {updateUserDevice} from '../redux/slices/generalSlice';
import PublicHome from '../pages/PublicHome';
import EditProfile from '../pages/EditProfile';

const Routes = () => {
  const dispatch = useDispatch();
  const checkUserDevice = useCallback(() => {
    const deviceWidth = window.innerWidth ||
        document.documentElement.clientWidth || document.body.clientWidth;
    const deviceType = deviceWidth < 768 ? 'mobile':'desktop';
    dispatch(updateUserDevice(deviceType));
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('resize', checkUserDevice);

    checkUserDevice();
  }, [checkUserDevice, dispatch]);

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: '/',
      element: <ProtectedRoute/>, // Wrap the component in ProtectedRoute
      children: [
        {
          path: '',
          element: <Home/>,
        },
        {
          path: '/edit-profile',
          element: <EditProfile />,
        },
        {
          path: '/settings',
          element: <Settings/>,
        },
        {
          path: '/vehicles',
          element: <Vehicles/>,
        },
        {
          path: '/share/vehicle/:vehicleId',
          element: <VehicleSharing/>,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: '/login',
      element: <Login/>,
    },
    {
      path: '/public/share/:shareToken',
      element: <PublicHome />,
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForNotAuthenticatedOnly,
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router}/>;
};

export default Routes;
