import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import io from 'socket.io-client';

import DevicesList from '../components/DevicesList';
import DevicesDetails from '../components/DevicesDetails';
import MobileHeader from '../components/MobileHeader';
import MobileSingularHeader from '../components/MobileSingularHeader';
import MobileSingularDetails from '../components/MobileSingularDetails';
import CloseMapViewButton from '../components/CloseMapViewButton';
import DesktopHeader from '../components/DesktopHeader';
import GoogleMaps from '../components/GoogleMaps';
import {get, getImageUrl} from '../helpers';
import {
  addNewAlarm, addNewDeviceLocation,
  updateDeviceList,
  updateDeviceTerminalInfo, updateDeviceTripSummaryInfo,
} from '../redux/slices/deviceSlice';
import PlaybackPopup from '../components/PlaybackPopup';
import Loading from '../components/Loading';
import ClosePlaybackButton from '../components/ClosePlaybackButton';
import PlaybackControl from '../components/PlaybackControl';
import EditVehiclePopup from '../components/EditVehiclePopup';
import Drawer from '../components/Drawer';
import Alarm from '../components/Alarm';
import {updateLoading} from '../redux/slices/generalSlice';

export default function Home() {
  const selectedDevice = useSelector((state) => state.device.selectedDevice);
  const showMapOnlyView = useSelector((state) => state.device.showMapOnlyView);
  const userDevice = useSelector((state) => state.general.userDevice);
  const showingPlayback = useSelector((state) => state.general.showingPlayback);
  const playbackPopup = useSelector((state) => state.general.playbackPopup);
  const vehicleInfoPopup = useSelector(
      (state) => state.general.vehicleInfoPopup);
  const loading = useSelector((state) => state.general.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(updateLoading(true));
      const token = localStorage.getItem('token');
      if (token) {
        const socket = io(process.env.REACT_APP_WS_URL, {
          withCredentials: true,
          extraHeaders: {
            Authorization: `Bearer ` + token,
          },
        });

        socket.on('connect', () => {
          console.log(socket.id);
        });

        socket.on('user_devices', (deviceList) => {
          dispatch(updateDeviceList(deviceList));

          deviceList.forEach((device) => {
            if (get(device, 'alarms', []).length > 0) {
              device.alarms.forEach((alarm) => {
                dispatch(addNewAlarm(alarm));
              });
            }
          });
          setTimeout(() => {
            dispatch(updateLoading(false));

            deviceList.forEach((device) => {
              const imageUrl = getImageUrl(
                  device.type,
                  device.terminal.status,
                  device.imei,
              );
              const marker = document.querySelector(`[src="${imageUrl}"]`);
              if (marker) {
                let rotation = device.terminal.last_location.course;
                marker.style.transform = `rotate(${rotation - 90}deg)`;
              }
            })
          }, 2000);
        });

        socket.on('device_status_changed', (response) => {
          dispatch(updateDeviceTerminalInfo(response));
          dispatch(updateDeviceTripSummaryInfo(response));
        });

        socket.on('device_alarm_added', (response) => {
          dispatch(addNewAlarm(response.alert));
          dispatch(updateDeviceTerminalInfo(response));
        });

        socket.on('device_location_changed', (response) => {
          dispatch(addNewDeviceLocation({
            imei: response.imei,
            newLocation: {
              'lat': get(response, 'location.latitude', 0),
              'lng': get(response, 'location.longitude', 0),
              'bearing': get(response, 'location.course', 0),
              'speed': get(response, 'location.speed', 0),
            },
          }));
          dispatch(updateDeviceTerminalInfo(response));
          dispatch(updateDeviceTripSummaryInfo(response));
        });

        return () => {
          socket.close();
        };
      } else {
        return () => {
        };
      }
    } catch (e) {
      console.log(e);
      return () => {
      };
    }
  }, [dispatch]);

  return (
      <div className="flex md:flex-row flex-col h-full relative">
        <div className="bg-blue-950 w-16 md:block hidden">
          <DesktopHeader/>
        </div>
        <div>
          {!showMapOnlyView && (
              <>
                {!selectedDevice && <MobileHeader/>}
                {selectedDevice && <MobileSingularHeader/>}
              </>
          )}
        </div>
        <div className="flex-1 relative">
          <div className="h-full map__container">
            <GoogleMaps/>
          </div>

          <div className={`${showingPlayback ? 'hidden':'block'}`}>
            {(userDevice === 'desktop' && selectedDevice) && (
                <MobileSingularDetails/>
            )}
            <div className={`${showMapOnlyView ? 'hidden':'block'}`}>
              <div className={`${((userDevice === 'mobile' &&
                  !selectedDevice) || userDevice === 'desktop') ?
                  'block':'hidden'}`}
              >
                <DevicesList/>
              </div>
              {selectedDevice && <DevicesDetails/>}
              {selectedDevice && <MobileSingularDetails/>}
            </div>
          </div>
          {showingPlayback && <PlaybackControl/>}
        </div>
        {showingPlayback && <ClosePlaybackButton/>}
        {showMapOnlyView && <CloseMapViewButton/>}
        <PlaybackPopup show={playbackPopup}/>
        <EditVehiclePopup show={vehicleInfoPopup}/>
        {loading && <Loading/>}
        <Drawer/>
        <Alarm/>
      </div>
  );
}
