import {createSlice} from '@reduxjs/toolkit';
import {get} from '../../helpers';

export const generalSlice = createSlice({
  name: 'general',
  initialState: {
    userDevice: "desktop",
    locationSharingPopup: false,
    showDrawerMenu: false,
    playbackPopup: false,
    vehicleInfoPopup: false,
    playbackDate: null,
    showingPlayback: false,
    playbackProgress: 0,
    playbackPlaying: false,
    playbackPlayingCompleted: false,
    playbackSpeed: 1,
    loading: false,
    creatingGeoFence: false,
    playbackPath: [],
    playbackStops: []
  },
  reducers: {
    updateUserDevice: (state, action) => {
      state.userDevice = action.payload;
    },
    updateCreatingGeoFence: (state, action) => {
      state.creatingGeoFence = action.payload;
    },
    updatePlaybackPopup: (state, action) => {
      state.playbackPopup = action.payload;
    },
    updatePlaybackDate: (state, action) => {
      state.playbackDate = action.payload;
    },
    updateShowingPlayback: (state, action) => {
      state.showingPlayback = action.payload;
    },
    updatePlaybackHistory: (state, action) => {
      state.playbackPath = get(action,'payload.path',[]);
      state.playbackStops = get(action,'payload.stops',[]);
    },
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateLocationSharingPopup: (state, action) => {
      state.locationSharingPopup = action.payload;
    },
    updatePlaybackProgress: (state, action) => {
      state.playbackProgress = action.payload;
    },
    updatePlaybackPlaying: (state, action) => {
      state.playbackPlaying = action.payload;
    },
    updatePlaybackCompleted: (state, action) => {
      state.playbackPlayingCompleted = action.payload;
    },
    updatePlaybackSpeed: (state, action) => {
      state.playbackSpeed = action.payload;
    },
    updateVehicleInfoPopup: (state, action) => {
      state.vehicleInfoPopup = action.payload;
    },
    updateShowDrawerMenu: (state, action) => {
      state.showDrawerMenu = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateUserDevice,
  updatePlaybackPopup,
  updatePlaybackDate,
  updateShowingPlayback,
  updatePlaybackHistory,
  updateLoading,
  updatePlaybackProgress,
  updatePlaybackPlaying,
  updatePlaybackCompleted,
  updatePlaybackSpeed,
  updateVehicleInfoPopup,
  updateLocationSharingPopup,
  updateShowDrawerMenu,
  updateCreatingGeoFence
} = generalSlice.actions;

export default generalSlice.reducer;
