import {useCallback, useEffect} from 'react';
import {FaPlay, FaPause} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {AiOutlineDashboard, AiOutlineClockCircle} from 'react-icons/ai';
import {GiHorizonRoad} from 'react-icons/gi';
import {
  updatePlaybackPlaying,
  updatePlaybackProgress, updatePlaybackCompleted, updatePlaybackSpeed,
} from '../redux/slices/generalSlice';
import {calculateDistance, getImageUrl, get} from '../helpers';

export default function PlaybackControl() {
  const path = useSelector((state) => state.general.playbackPath);
  const isPlaying = useSelector((state) => state.general.playbackPlaying);
  const progress = useSelector((state) => state.general.playbackProgress);
  const selectedDevice = useSelector((state) => state.device.selectedDevice);
  const playbackSpeed = useSelector((state) => state.general.playbackSpeed);
  const list = useSelector((state) => state.device.list);
  const isCompleted = useSelector(
      (state) => state.general.playbackPlayingCompleted);
  const dispatch = useDispatch();

  const selectedDeviceDetails = list.find((item) => item.imei === selectedDevice);

  const updateMarker = useCallback((locationIndex) => {
    let imageUrl = getImageUrl(
        get(selectedDeviceDetails, 'type', 'car'),
        'moving',
        selectedDevice,
    )
    const marker = document.querySelector(`[src="${imageUrl}"]`);

    if (marker) {
      let rotation = get(path, `${locationIndex}.bearing`, 0);
      marker.style.transform = `rotate(${rotation - 90}deg)`;
    }
  }, [path, selectedDevice, selectedDeviceDetails]);

  useEffect(() => {
    let interval;

    if (isPlaying) {
      interval = setInterval(() => {
        let newProgress = progress + 1;
        if (newProgress >= path.length) {
          dispatch(updatePlaybackPlaying(false));
          dispatch(updatePlaybackCompleted(true));
          clearInterval(interval);
        }
        updateMarker(progress);

        dispatch(updatePlaybackProgress(newProgress));
      }, 1000 / (playbackSpeed * 5));
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => clearInterval(interval);
  }, [dispatch, isPlaying, path, playbackSpeed, progress, selectedDevice, updateMarker]);

  if (!selectedDeviceDetails) {
    return null;
  }

  const startSimulation = () => {
    if (isCompleted) {
      dispatch(updatePlaybackCompleted(false));
      dispatch(updatePlaybackProgress(0));
    }
    dispatch(updatePlaybackPlaying(!isPlaying));
  };

  function calculateTotalDistance(points) {
    let totalDistance = 0;
    for (let i = 0; i < points.length - 1; i++) {
      const {lat: lat1, lng: lon1} = points[i];
      const {lat: lat2, lng: lon2} = points[i + 1];
      totalDistance += calculateDistance(lat1, lon1, lat2, lon2);
    }

    return totalDistance;
  }

  function formatTime(utcTimeString) {
    const utcDate = new Date(utcTimeString);
    return utcDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
  }

  function changePlaybackSpeed() {
    let newPlaybackSpeed = playbackSpeed;
    if (playbackSpeed === 2) {
      newPlaybackSpeed = 1;
    } else {
      newPlaybackSpeed += 0.25;
    }
    dispatch(updatePlaybackSpeed(newPlaybackSpeed));
  }

  return (
      <div className="p-2 absolute bottom-0 left-0 right-0 z-[100]">
        <div className="md:max-w-md w-full mx-auto">
          <div
              className="w-full bg-white rounded-lg shadow border border-neutral-200 py-3 px-4">
            <div className="mb-2">
              <div className="text-black text-sm font-bold mb-5 text-center">
                {selectedDeviceDetails.name}
              </div>
              {progress <= 0 && (
                  <div className="text-zinc-500 text-sm font-bold my-5">
                    Click on play button to view playback.
                  </div>
              )}
              {progress > 0 && progress < path.length &&
                  (<div className="flex justify-between">
                    <div className="flex items-center">
                    <span className="mr-1">
                      <AiOutlineClockCircle color="#7F7F7F" size={32}/>
                    </span>
                      <span className="text-zinc-500 text-sm font-bold">
                      {formatTime(path[progress].timestamp)}
                    </span>
                    </div>
                    <div className="flex items-center">
                    <span className="mr-1">
                      <AiOutlineDashboard color="#7F7F7F" size={32}/>
                    </span>
                      <span className="text-zinc-500 text-sm font-bold">
                      {path[progress].speed.toFixed(1)} Km/Hr
                    </span>
                    </div>
                    <div className="flex items-center">
                    <span className="mr-1">
                      <GiHorizonRoad color="#7F7F7F" size={32}/>
                    </span>
                      <span className="text-zinc-500 text-sm font-bold">
                      {calculateTotalDistance(path.slice(0, progress + 1))
                          .toFixed(1)} Kms
                    </span>
                    </div>
                  </div>)}
            </div>
            <div
                className="bg-white rounded-3xl shadow flex justify-between p-1 items-center">
              <button
                  className="p-2 bg-neutral-200 rounded-full flex items-center justify-center w-12 h-12 cursor-pointer"
                  onClick={startSimulation}
              >
                <span>
                  {isPlaying ?
                      <FaPause color="#242412" size={24}/>:
                      <FaPlay color="#242412" size={24}/>}
                </span>
              </button>
              <div className="flex-1 mx-2">
                <input
                    type="range" min={0} max={path.length} value={progress}
                    onChange={(e) => {
                      updateMarker(e.target.value)
                      dispatch(updatePlaybackPlaying(false));
                      dispatch(
                          updatePlaybackProgress(parseInt(e.target.value)));
                    }}/>
              </div>
              <button
                  className="p-2 cursor-pointer w-16 text-center font-bold"
                  onClick={changePlaybackSpeed}>
                {playbackSpeed}X
              </button>
            </div>
          </div>
        </div>
      </div>
  );
}
