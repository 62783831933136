import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Calendar from 'reactjs-calendar-date-picker';
import {toast} from 'react-toastify';

import Popup from './Popup';
import {
  updateLoading,
  updatePlaybackDate, updatePlaybackHistory,
  updatePlaybackPopup, updateShowingPlayback,
} from '../redux/slices/generalSlice';
import {getLast7Days, isValidDate, get} from '../helpers';
import axios from '../axios';

export default function PlaybackPopup({show}) {
  const selectedDevice = useSelector((state) => state.device.selectedDevice);
  const playbackDate = useSelector((state) => state.general.playbackDate);
  const [selected, setSelected] = useState({});
  const dispatch = useDispatch();

  const last7Days = getLast7Days();
  const dateFormat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const setPlaybackDate = (date) => {
    if (date && date !== 'custom') {
      dispatch(updatePlaybackDate(date.toISOString()));
    } else {
      dispatch(updatePlaybackDate(date));
    }
  };

  const closePlaybackPopup = () => {
    dispatch(updatePlaybackPopup(false));
    setPlaybackDate(null);
  };

  const showPlayback = () => {
    if (playbackDate) {
      const {fromDate, toDate} = selected || {};
      if (playbackDate === 'custom' &&
          (!isValidDate(fromDate) || !isValidDate(toDate))) {
        toast.error("Please select valid from and to dates!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        return false;
      }
      dispatch(updateLoading(true));
      let startDate;
      let endDate;
      if (playbackDate !== 'custom') {
        const selectedPlaybackDate = new Date(playbackDate);
        selectedPlaybackDate.setHours(0, 0, 0, 0);
        startDate = selectedPlaybackDate.toISOString();
        selectedPlaybackDate.setHours(23, 59, 59, 999);
        endDate = selectedPlaybackDate.toISOString();
      }else{
        const customFromDate = new Date(fromDate);
        customFromDate.setHours(0, 0, 0, 0);
        startDate = customFromDate.toISOString();
        const customToDate = new Date(toDate);
        customToDate.setHours(23, 59, 59, 999);
        endDate = customToDate.toISOString();
      }
      axios.get(
          `/user/vehicle/playback/${selectedDevice}?startDate=${startDate}&endDate=${endDate}`)
          .then((response) => {
            if (get(response, 'success')) {
              let path = get(response, 'path', []);
              if (path.length > 0) {
                dispatch(updatePlaybackHistory({
                  path: get(response, 'path', []),
                  stops: get(response, 'stops', []),
                }));
                dispatch(updateShowingPlayback(true));
                closePlaybackPopup();
              } else {
                toast.error("No history available for given date!", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                });
              }
            }
            dispatch(updateLoading(false));
          })
          .catch((e) => {
            dispatch(updateLoading(false));
          });
    }
  };

  const onCustomDateChange = (arg) => {
    const {from, to} = arg;

    const fromDate = new Date(from).toISOString();
    const toDate = to ? new Date(to).toISOString():'';

    setSelected({fromDate, toDate});
  };

  return (
      <Popup title="Playback" onClose={closePlaybackPopup} show={show}>
        <div className="grid md:grid-cols-4 grid-cols-3 gap-2 my-5">
          {last7Days.map((date, index) => {
            const today = new Date();
            const todayDate = today.toLocaleDateString('en-US', dateFormat);
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            const yesterdayDate = yesterday.toLocaleDateString('en-US',
                dateFormat);

            let formattedPlaybackDate = null;
            if (playbackDate && playbackDate !== 'custom') {
              const selectedPlaybackDate = new Date(playbackDate);
              formattedPlaybackDate = selectedPlaybackDate.toLocaleDateString(
                  'en-US', dateFormat);
            }

            const formattedDate = date.toLocaleDateString('en-US', dateFormat);
            let label;
            if (todayDate === formattedDate) {
              label = 'Today';
            } else if (yesterdayDate === formattedDate) {
              label = 'Yesterday';
            } else {
              label = date.toLocaleDateString('en-US', {
                month: 'short', day: '2-digit',
              });
            }

            return (
                <button
                    key={`playback-date-${index}`}
                    onClick={() => {
                      if (formattedPlaybackDate === formattedDate) {
                        setPlaybackDate(null);
                      } else {
                        setPlaybackDate(date);
                      }
                    }}
                    className={`p-2 opacity-80 ${formattedPlaybackDate ===
                    formattedDate ?
                        'bg-blue-950 border-blue-950 text-white':
                        'border-neutral-200 bg-white text-neutral-400'} rounded-3xl shadow border text-sm font-normal tracking-tight text-center cursor-pointer`}>
                  {label}
                </button>
            );
          })}
          <button
              onClick={() => {
                if (playbackDate === 'custom') {
                  setPlaybackDate(null);
                } else {
                  setPlaybackDate('custom');
                }
              }}
              className={`p-2 opacity-80 ${playbackDate === 'custom' ?
                  'bg-blue-950 border-blue-950 text-white':
                  'border-neutral-200 bg-white text-neutral-400'} rounded-3xl shadow border text-sm font-normal tracking-tight text-center cursor-pointer`}>
            Custom
          </button>
        </div>
        <div className={`${playbackDate === 'custom' ? 'block':'hidden'}`}>
          <Calendar onChange={onCustomDateChange}/>
        </div>
        {playbackDate && (
            <div className="mt-5 mb-2 text-center">
              <button
                  className="bg-blue-950 rounded-2xl text-center text-white text-base font-normal py-2 px-5 mx-2 font-bold"
                  disabled={!playbackDate}
                  onClick={showPlayback}
              >
                Confirm
              </button>
            </div>
        )}
      </Popup>
  );
}
