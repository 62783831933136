import {BsFillPersonFill, BsFillGearFill, BsPhone} from 'react-icons/bs';
import {BiSupport, BiLogOut} from 'react-icons/bi';
import {MdKeyboardArrowRight, MdHome} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../provider/authProvider';
import {get} from '../helpers';
import {updateShowDrawerMenu} from '../redux/slices/generalSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useRef} from 'react';

export default function Drawer() {
  const showDrawerMenu = useSelector((state) => state.general.showDrawerMenu);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {logout, userInfo} = useAuth();
  const drawerMenuRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (drawerMenuRef.current && !drawerMenuRef.current.contains(event.target)) {
        dispatch(updateShowDrawerMenu(false));
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dispatch]);


  const topMenuItems = [
    {
      icon: <MdHome color="#001C6F" size={24}/>,
      label: 'Home',
      onAction: () => {
        navigate('/');
      },
    },
    {
      icon: <BsPhone color="#001C6F" size={24}/>,
      label: 'Device details',
      onAction: () => {
        navigate('/vehicles');
      },
    },
    {
      icon: <BsFillPersonFill color="#001C6F" size={24}/>,
      label: 'Edit profile',
      onAction: () => {
        navigate('/edit-profile');
      },
    },
    {
      icon: <BsFillGearFill color="#001C6F" size={24}/>,
      label: 'Settings',
      onAction: () => {
        navigate('/settings');
      },
    },
  ];
  const bottomMenuItems = [
    {
      icon: <BiSupport color="#001C6F" size={24}/>,
      label: 'Support',
      onAction: () => {
      },
    },
    {
      icon: <BiLogOut color="#001C6F" size={24}/>,
      label: 'Logout',
      onAction: () => {
        logout();
        navigate('/login', {replace: true});
      },
    },
  ];

  const MenuItem = ({icon, label, onAction}) => {
    return (
        <div
            className="flex justify-between cursor-pointer px-3 py-2 border-y border-neutral-200 menu-item"
            onClick={() => {
              dispatch(updateShowDrawerMenu(false));
              onAction();
            }}
        >
          <div className="flex items-center">
            <span className="mr-3">
              {icon}
            </span>
            <span className="text-zinc-500 text-base font-normal">
              {label}
            </span>
          </div>
          <div>
            <MdKeyboardArrowRight color="#001C6F" size={24}/>
          </div>
        </div>
    );
  };

  return (
      <div
          id="drawer-menu"
          className={`z-[150] fixed top-0 left-0 bottom-0 w-full bg-black bg-opacity-40 ${showDrawerMenu ? 'opened':'closed'}`}>
        <div
            ref={drawerMenuRef}
            className="h-full md:max-w-md w-10/12 bg-white flex flex-col justify-between py-6">
          <div className="w-full drawer-menu">
            <div
                className="text-blue-950 text-base font-bold text-center w-full mb-2">
              Welcome!
            </div>
            <div
                className="text-zinc-500 text-base font-normal w-full mb-6 text-center">
              {get(userInfo, 'displayName', null)}
            </div>
            <div>
              {
                topMenuItems.map((menu, index) => {
                  return (
                      <MenuItem
                          key={`top-menu-item-${index}`}
                          label={menu.label}
                          icon={menu.icon}
                          onAction={menu.onAction}
                      />
                  );
                })
              }
            </div>
          </div>
          <div className="w-full drawer-menu">
            {
              bottomMenuItems.map((menu, index) => {
                return (
                    <MenuItem
                        key={`bottom-menu-item-${index}`}
                        label={menu.label}
                        icon={menu.icon}
                        onAction={menu.onAction}
                    />
                );
              })
            }
          </div>
        </div>
      </div>
  );
}
